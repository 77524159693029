import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RoutesConfig } from '../config/routes';
import '../sass/style.scss';
import 'swiper/scss';
import {useAuthStore} from "../store";
import {useNavigate} from "react-router-dom";


function App() {
    return (
        <RouterProvider router={createBrowserRouter(RoutesConfig)} />
    );
}

export default App;
