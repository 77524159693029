import {useAuthStore} from "../../store";
import copy from '../../assets/sprite/copy.svg';
import {useNavigate} from "react-router-dom";
import {useEffect, useRef} from "react";


export const DisconnectModal = ({isOpen, onClose, buttonRef}) => {
    const {user, balance, logout} = useAuthStore(state => state);
    const modalRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            modalRef.current && !modalRef.current.contains(event.target)
            && buttonRef.current && !buttonRef.current.contains(event.target)
        ) {
            onClose();
        }
    };

    const navigate = useNavigate();

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(user);
        } catch (err) {
        }
    };
    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    if (!isOpen) return null;
    return (
        <div className={isOpen ? "wallet__content open" : "wallet__content"} id="wallet" ref={modalRef}>
            <div className="wallet__tabs">
                <ul className="wallet__tabs-list">
                    <li className="wallet__tabs-item">
                        <button className="wallet__tabs-btn wallet__tabs-btn--active" data-tabs-path="wallet">Wallet
                        </button>
                    </li>
                    <li className="wallet__tabs-item">
                        <button className="wallet__tabs-btn" data-tabs-path="history">Transactions</button>
                    </li>
                </ul>
                <div className="wallet__tabs-content wallet__tabs-content--active" data-tabs-target="wallet">
                    <div className="wallet__content-info">
                        <div className="wallet__content-adress">
                            <div className="wallet__content-title">My address</div>
                            <div className="wallet__content-details">
                                <input readOnly="readonly" id="copyurl999" data-type="id" data-length="0"
                                       data-wallet="{{Auth::user()->wallettype}}"
                                       className="refs form-control wallet__adress" type="text"
                                       value={user || ''} name="id" style={{
                                    width: '100%',
                                    color: 'black'
                                }} />

                                <button id="copy999"
                                        data-toggle="tooltip" data-placement="bottom" title=""
                                        className="btn btn-outline-secondary waves-effect" type="button"
                                        data-original-title="Скопировать ссылку" onClick={copyToClipboard}>

                                    <img src={copy} alt="" />
                                </button>
                            </div>
                        </div>
                        <div className="wallet__content-adress">
                            <div className="wallet__content-title">Balance</div>
                            <div className="wallet__content-details"><span className="wallet__balance">{balance} BNB</span>
                            </div>
                        </div>
                    </div>
                    <button className="disconnect" id="disconnect-wallet" onClick={() =>{
                        logout();
                        navigate('/');
                        onClose()
                    }}>Disconnect wallet</button>
                </div>
                <div className="wallet__tabs-content" data-tabs-target="history">
                    <div className="wallet__content-info">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea ut excepturi perspiciatis.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}