import Logo from '../../assets/logo.png';
import youtube from '../../assets/youtube.svg';
import twitter from '../../assets/twitter.svg';

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="container__small">
                <div className="footer__inner">
                    <div className="footer__logo">
                        <img className="logo" width="86" height="76" src={Logo} alt="DEGY logo" />
                    </div>

                    <div className="footer__info">
                        <ul className="footer__nav nav">
                            <li className="nav__item">
                                <a className="nav__link nav__link--active" href="#">Main</a>
                            </li>
                            <li className="nav__item">
                                <a className="nav__link" href="/#about-yak">Our legend</a>
                            </li>
                            <li className="nav__item">
                                <a className="nav__link" href="/#roadmap-yak">Roadmap</a>
                            </li>
                            <li className="nav__item">
                                <a className="nav__link" href="/#faq-yak">FAQ</a>
                            </li>
                            <li className="nav__item">
                                <a className="nav__link" href="/mint">Mint</a>
                            </li>

                            <li className="nav__item">
                                <a className="nav__link" href="#">Smartcontract</a>
                            </li>
                        </ul>

                        <div className="footer__socials-info">
                            <div className="footer__socials-title">Join our community</div>

                            <ul className="footer__socials">

                                <li className="footer__socials-item">
                                    <a className="footer__socials-link" href="https://www.youtube.com/channel/UCqyEX69azPgBr77F1cW1ZbQ" target="_blank">
                                        <img className="logo" width="25" height="20" src={youtube} alt="DEGY logo" />

                                    </a>
                                </li>
                                <li className="footer__socials-item">
                                    <a className="footer__socials-link" href="https://twitter.com/degy_io"  target="_blank">
                                        <img className="logo" width="25" height="20" src={twitter} alt="DEGY logo" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="footer__bot">
                        <div className="footer__copyright">Copyright © 2023. DEGY. All rights reserved.</div>
                        <div className="footer__terms">
                            <a className="footer__terms-links" href="#">Terms & Conditions</a>
                            <a className="footer__terms-links" href="#">Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}