import {useState} from "react";
import {MainModal} from "../../components/Modal";
import {useAuthStore} from "../../store";
import {createPortal} from "react-dom";
import {useNavigate} from "react-router-dom";

export const ProfileSoon = () => {
    const {connectToMetamask, connectToBinance, connectToWallet, connectToTrust} = useAuthStore(state => state);
    const navigate = useNavigate();
    const connect = useAuthStore(state => state);
    if(connect.connectedTo) {
        navigate('/profile')
    }
    const [showWalletModal, setShowWalletModal] = useState(false);
    return (
        <>
            <section className="head head__small">
                <div className="head__title subtitle">Profile</div>
                <div className="head__connect">
                    <div className="head__connect-wrapper">
                        <div className="head__soon-title">Connect wallet</div>
                        <div className="head__soon-text">Connect wallet to enter your profile page</div>
                        <div className="wallet" data-path="wallet"  onClick={() => setShowWalletModal(true)}>Connect wallet</div>
                    </div>
                </div>
            </section>
            <div className="head__overlay head__overlay--profile"></div>
            {showWalletModal && createPortal(
                <MainModal
                    isOpen={showWalletModal}
                    onClose={() => setShowWalletModal(false)}
                    connectToMetamask={connectToMetamask}
                    connectToBinance={connectToBinance}
                    connectToWallet={connectToWallet}
                    connectToTrust={connectToTrust}
                />,
                document.body
            )}
        </>
    )
}
