import sprite from '../../assets/sprite/diamond.svg';
import group from '../../assets/topimg/Group.png';
import copy from '../../assets/topimg/copybut.png';
import common from "../../assets/topimg/mint/common.png";
import uncommon from "../../assets/topimg/mint/uncommon.png";
import epic from "../../assets/topimg/mint/epic.png";
import rare from "../../assets/topimg/mint/rare.png";
import ETH from "../../assets/eth.png";
import question from "../../assets/question.svg";
import wtwight from "../../assets/wtwit.png";
import { useEffect, useState} from "react";
import {createPortal} from "react-dom";
import {MainModal} from "../../components/Modal";
import {FarmModal} from "../../components/Modal/FarmModal";
import {useAuthStore} from "../../store";
import {PlaceABidModal} from "../../components/Modal/PlaceABidModal";
import {Referral} from "../../components/Referal";
import {Head} from "./components/Head";

const images = [
    {
        img: common,
        text: 'common',
        number: '0,148'
    },
    {
        img: uncommon,
        text: 'uncommon',
        number: '0,735'
    },
    {
        img: epic,
        text: 'epic',
        number: '0,542'
    },
    {
        img: rare,
        text: 'rare',
        number: '0,52'
    },
];

export const Mint = () => {
    const [activeTab, setActiveTab] = useState(0);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showFarmModal, setShowFarmModal] = useState(false);
    const [showPlaceABidModal, setShowPlaceABidModal] = useState(false);
    const [mintBoard, setBoard] = useState([]);
    const [showWalletModal, setShowWalletModal] = useState(false);
    const {
        connectToMetamask,
        connectToBinance,
        connectToWallet,
        connectToTrust,
        connectToContract,
        getUserBoard,
        getUserInfo,
        investInDEGY,
        getInvestBack,
        connectToPublicProviderContract,
        balance,
        userInfo,
        board,
        user
    } = useAuthStore(state => state);

    let auctionStart = true;
    const bid = +userInfo[0] ;

    const copyToClipboard =  (text) => async () => {
        try {
            await navigator.clipboard.writeText(text);
        } catch (err) {
        }
    };
    const handleTabChange = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {
        const fetchUserBoard = async () => {
            await getUserBoard();
        };

        connectToPublicProviderContract()

        if (user) {
            connectToContract();
            fetchUserBoard();
            getUserInfo(user);
        }
    }, [user]);

    useEffect(() => {
        setBoard(board)
    }, [board]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    return (
        <main>
            <Head />

            <section className="content">
                <div className="container__content">
                    <div className="content__inner">
                        <div className="content__left">
                            <div className="content__card card">
                                <div className="card__image">
                                    <img className='card__img js-img' width='211' height='211' src={images[currentImageIndex].img} alt='' />
                                </div>
                                <div className="card__info">
                                    <div className="card__series">Delivery guys</div>
                                    <div className="card__title">Delivery Guy #3789</div>
                                    <div className="card__divider"></div>
                                    <div className="card__footer">
                                        <div className={`${images[currentImageIndex].text} js-rare-status card__status`}>
                                            <img className='card__img' src={sprite} alt='' />
                                            <span className="js-rare">{images[currentImageIndex].text}</span>
                                        </div>
                                        <div className="card__price">
                                            <img className='card__img' width='211' height='211' src={ETH} alt='' />
                                            <span className='chpricejs'>{images[currentImageIndex].number}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!user && <button className="card__btn" data-path="wallet"   onClick={() => setShowModal(true)}>connect wallet</button>}
                        </div>
                        <div className="content__right">
                            <div className="content__info">
                                <div className="descr">{user ? '...MINT SALE STARTED!' : 'Place Your Bid and Climb the Leaderboard for Exclusive NFTs'}</div>
                                <div className="text">

                                    Place your bid in ETH to join a list of bidders! Your bid grants you a dynamic position that updates in real time, giving you the chance to climb the ranks. The top 5,555 bidders will secure exclusive NFTs based on their list placement: top 55 receive an Epic NFT, next 500 earn a Rare NFT, followed by 1,500 getting an Uncommon NFT, and the final 3,500 bidders walk away with a Common NFT. Jump in, raise your bid, and see where you land in this thrilling NFT opportunity!
                                </div>
                                <button className="content__more" data-path="farm" onClick={() => setShowFarmModal(true)}>
                                    <span>More about DEGY NFT Auction</span>
                                    <img src={question} alt="" />
                                </button>

                                {user ? <div className="content__timer timer">

                                    <div className="timer__text">Bidding ends in…</div>
                                    <   div className="timer__count-content">
                                        <div className="timer__count-item timer__count-days">
                                            <div className="timer__count-val">10</div>
                                        </div>
                                        <div className="timer__count-separator">:</div>
                                        <div className="timer__count-item timer__count-hours">
                                            <div className="timer__count-val">11</div>
                                        </div>
                                        <div className="timer__count-separator">:</div>
                                        <div className="timer__count-item timer__count-minutes">
                                            <div className="timer__count-val">36</div>
                                        </div>
                                    </div>
                                </div> : <div className="content__timer timer"  style={{background:'linear-gradient(98.59deg,#4e55ff 8.54%,#3a7cfd 34.57%,#24a1fb 62.94%,#00daf8 109.42%)'}}>

                                    <div className="timer__text">Bidding ends in…</div>
                                    <div className="timer__count-content">
                                        <div className="timer__count-item timer__count-days">
                                            <div className="timer__count-val">10</div>
                                        </div>
                                        <div className="timer__count-separator">:</div>
                                        <div className="timer__count-item timer__count-hours">
                                            <div className="timer__count-val">11</div>
                                        </div>
                                        <div className="timer__count-separator">:</div>
                                        <div className="timer__count-item timer__count-minutes">
                                            <div className="timer__count-val">36</div>
                                        </div>
                                    </div>
                                </div>}

                                {user && <div className="card__calculator">

                                    {auctionStart && <div className="card__calculator-content" style={{
                                        justifyContent: 'center'
                                    }}>

                                        <button
                                            className="card__calculator-btn card__btn "
                                            data-path="mint"
                                            onClick={() => setShowPlaceABidModal(true)}
                                        >
                                            Make a bid
                                        </button>
                                    </div>}

                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {user && <Referral openModal={() => setShowFarmModal(true)} info={userInfo} hash={user} />}

            <section className="content__profile">
                <div className="container">
                    <div className="content__inner">
                        <div className="content__tabs">
                            <ul className="content__tabs-list active-menu">
                                <li className="content__tabs-item" onClick={() => handleTabChange(0)}>
                                    <button className={activeTab == 0 ? "content__tabs-btn content__tabs-btn--active" : "content__tabs-btn"} data-tabs-path="all"
                                            disabled="">
                                        All Top
                                    </button>
                                </li>
                                <li className="content__tabs-item" onClick={() => handleTabChange(1)}>
                                    <button className={activeTab == 1 ? "content__tabs-btn content__tabs-btn--active comm" : "content__tabs-btn"} data-tabs-path="onfarm" disabled="">
                                        <img className="card__img" width="211" height="211" src={group}
                                             alt="" style={{
                                            position:'relative',
                                            top: '1px',
                                                 width: '12px',
                                                height: '12px',
                                            marginRight: '5px'
                                        }}/>
                                            Common
                                    </button>
                                </li>
                                <li className="content__tabs-item" onClick={() => handleTabChange(2)}>
                                    <button className={activeTab == 2 ? "content__tabs-btn content__tabs-btn--active" : "content__tabs-btn"} data-tabs-path="from-mint" disabled="">
                                        <img className="card__img" width="211" height="211" src={group}
                                             alt=""  style={{
                                            position:'relative',
                                            top: '1px',
                                            width: '12px',
                                            height: '12px',
                                            marginRight: '5px'
                                        }}/>
                                            Uncommon
                                    </button>
                                </li>
                                <li className="content__tabs-item" onClick={() => handleTabChange(3)}>
                                    <button className={activeTab == 3 ? "content__tabs-btn content__tabs-btn--active rare" : "content__tabs-btn"} data-tabs-path="from-airdrop" disabled="">
                                        <img className="card__img" width="211" height="211" src={group}
                                             alt=""  style={{
                                                 position:'relative',
                                            top: '1px',
                                            width: '12px',
                                            height: '12px',
                                            marginRight: '5px'
                                        }}/>
                                            Rare
                                    </button>
                                </li>
                                <li className="content__tabs-item" onClick={() => handleTabChange(4)}>
                                    <button className={activeTab == 4 ? "content__tabs-btn content__tabs-btn--active epic" : "content__tabs-btn"} data-tabs-path="from-airdrop" disabled="">
                                        <img className="card__img" width="211" height="211" src={group}
                                             alt=""  style={{
                                            position:'relative',
                                            top: '1px',
                                            width: '12px',
                                            height: '12px',
                                            marginRight: '5px'
                                        }}/>
                                            Epic
                                    </button>
                                </li>
                            </ul>
                            {auctionStart ? (
                                <div className="content__tabs-wrapper flex-ir">
                                    <div className="content__tabs-inner ir-mw">
                                        <div className={activeTab == 0 ? "content__tabs-content content__tabs-content--active" : "content__tabs-content"}
                                             data-tabs-target="all">
                                            <div className=" ">
                                                <div className="">
                                                    <div className="js-profile-all">

                                                        <div className="table">
                                                            <div>
                                                                <div className="dash-tittle">
                                                                    <div scope="col" className="dash-23">Rank</div>
                                                                    <div scope="col" className="dash-54">User</div>
                                                                    <div scope="col" className="dash-23">Bid</div>
                                                                </div>
                                                            </div>

                                                            {mintBoard.length !== 0 ? mintBoard.map((item, index) => (
                                                                <div className={user === Object.keys(item)[0] ? 'dash-desc active' : 'dash-desc '} key={Object.keys(item)[0]}>
                                                                    <div className="dash-23 dane-id">{index + 1}</div>

                                                                    <div className="dash-54 gane-adapt">
                                                                        <input readOnly="readonly" id="copyurl9999"
                                                                               data-type="id" data-length="0" data-wallet="meta"
                                                                               className="refs form-control" type="text"
                                                                               value={Object.keys(item)[0]}
                                                                               name="id" />
                                                                        <button id="copy9999"
                                                                                data-toggle="tooltip"
                                                                                data-placement="bottom" title=""
                                                                                className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                                type="button"
                                                                                data-original-title="Скопировать ссылку"
                                                                                onClick={copyToClipboard(Object.keys(item)[0])}
                                                                        >
                                                                            <img src={copy} alt="" />
                                                                        </button>
                                                                    </div>
                                                                    <div className="dash-23 dane-price">
                                                                        {item[Object.keys(item)[0]]} ETH
                                                                    </div>
                                                                </div>
                                                            )) : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
                                                                <div className='dash-desc' key={item}>
                                                                    <div className="dash-23 dane-id">{index + 1}</div>

                                                                    <div className="dash-54 gane-adapt">

                                                                    </div>
                                                                    <div className="dash-23 dane-price">

                                                                    </div>
                                                                </div>

                                                            ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={activeTab == 1 ? "content__tabs-content content__tabs-content--active" : "content__tabs-content"} data-tabs-target="onfarm">
                                            <div className=" ">
                                                <div className="">
                                                    <div className="js-profile-all">

                                                        <div className="table">
                                                            <div>
                                                                <div className="dash-tittle">
                                                                    <div scope="col" className="dash-23">Rank</div>
                                                                    <div scope="col" className="dash-54">User</div>
                                                                    <div scope="col" className="dash-23">Bid</div>
                                                                </div>
                                                            </div>


                                                            {mintBoard.length !== 0 ? mintBoard.map((item, index) => {
                                                                if(index > 3500) {
                                                                    return (
                                                                        <div className={user === Object.keys(item)[0] ? 'dash-desc active comm' : 'dash-desc '} key={Object.keys(item)[0]}>
                                                                            <div className="dash-23 dane-id">{index + 1}</div>

                                                                            <div className="dash-54 gane-adapt">
                                                                                <input readOnly="readonly" id="copyurl9999"
                                                                                       data-type="id" data-length="0" data-wallet="meta"
                                                                                       className="refs form-control" type="text"
                                                                                       value={Object.keys(item)[0]}
                                                                                       name="id" />
                                                                                <button id="copy9999"
                                                                                        data-toggle="tooltip"
                                                                                        data-placement="bottom" title=""
                                                                                        className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                                        type="button"
                                                                                        data-original-title="Скопировать ссылку"
                                                                                        onClick={copyToClipboard(Object.keys(item)[0])}
                                                                                >
                                                                                    <img src={copy} alt="" />
                                                                                </button>
                                                                            </div>
                                                                            <div className="dash-23 dane-price">
                                                                                {item[Object.keys(item)[0]]} ETH
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                            }) : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
                                                                <div className='dash-desc' key={item}>
                                                                    <div className="dash-23 dane-id">{index + 1}</div>

                                                                    <div className="dash-54 gane-adapt">

                                                                    </div>
                                                                    <div className="dash-23 dane-price">

                                                                    </div>
                                                                </div>

                                                            ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={activeTab == 2 ? "content__tabs-content content__tabs-content--active" : "content__tabs-content"} data-tabs-target="from-mint">
                                            <div className=" ">
                                                <div className="">
                                                    <div className="js-profile-all">

                                                        <div className="table">
                                                            <div>
                                                                <div className="dash-tittle">
                                                                    <div scope="col" className="dash-23">Rank</div>
                                                                    <div scope="col" className="dash-54">User</div>
                                                                    <div scope="col" className="dash-23">Bid</div>
                                                                </div>
                                                            </div>


                                                            {mintBoard.length !== 0 ? mintBoard.map((item, index) => {
                                                                if(index < 3500 && index > 1500) {
                                                                    return (
                                                                        <div className={user === Object.keys(item)[0] ? 'dash-desc active' : 'dash-desc '} key={Object.keys(item)[0]}>
                                                                            <div className="dash-23 dane-id">{index + 1}</div>

                                                                            <div className="dash-54 gane-adapt">
                                                                                <input readOnly="readonly" id="copyurl9999"
                                                                                       data-type="id" data-length="0" data-wallet="meta"
                                                                                       className="refs form-control" type="text"
                                                                                       value={Object.keys(item)[0]}
                                                                                       name="id" />
                                                                                <button id="copy9999"
                                                                                        data-toggle="tooltip"
                                                                                        data-placement="bottom" title=""
                                                                                        className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                                        type="button"
                                                                                        data-original-title="Скопировать ссылку"
                                                                                        onClick={copyToClipboard(Object.keys(item)[0])}
                                                                                >
                                                                                    <img src={copy} alt="" />
                                                                                </button>
                                                                            </div>
                                                                            <div className="dash-23 dane-price">
                                                                                {item[Object.keys(item)[0]]} ETH
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                            }) : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
                                                                <div className='dash-desc' key={item}>
                                                                    <div className="dash-23 dane-id">{index + 1}</div>

                                                                    <div className="dash-54 gane-adapt">

                                                                    </div>
                                                                    <div className="dash-23 dane-price">

                                                                    </div>
                                                                </div>

                                                            ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={activeTab == 3 ? "content__tabs-content content__tabs-content--active" : "content__tabs-content"} data-tabs-target="from-airdrop">
                                            <div className=" ">
                                                <div className="">
                                                    <div className="js-profile-all">

                                                        <div className="table">
                                                            <div>
                                                                <div className="dash-tittle">
                                                                    <div scope="col" className="dash-23">Rank</div>
                                                                    <div scope="col" className="dash-54">User</div>
                                                                    <div scope="col" className="dash-23">Bid</div>
                                                                </div>
                                                            </div>


                                                            {mintBoard.length !== 0 ? mintBoard.map((item, index) => {
                                                                if(index < 1500 && index > 500) {
                                                                    return (
                                                                        <div className={user === Object.keys(item)[0] ? 'dash-desc active rare' : 'dash-desc '} key={Object.keys(item)[0]}>
                                                                            <div className="dash-23 dane-id">{index + 1}</div>

                                                                            <div className="dash-54 gane-adapt">
                                                                                <input readOnly="readonly" id="copyurl9999"
                                                                                       data-type="id" data-length="0" data-wallet="meta"
                                                                                       className="refs form-control" type="text"
                                                                                       value={Object.keys(item)[0]}
                                                                                       name="id" />
                                                                                <button id="copy9999"
                                                                                        data-toggle="tooltip"
                                                                                        data-placement="bottom" title=""
                                                                                        className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                                        type="button"
                                                                                        data-original-title="Скопировать ссылку"
                                                                                        onClick={copyToClipboard(Object.keys(item)[0])}
                                                                                >
                                                                                    <img src={copy} alt="" />
                                                                                </button>
                                                                            </div>
                                                                            <div className="dash-23 dane-price">
                                                                                {item[Object.keys(item)[0]]} ETH
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                            }) : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
                                                                <div className='dash-desc' key={item}>
                                                                    <div className="dash-23 dane-id">{index + 1}</div>

                                                                    <div className="dash-54 gane-adapt">

                                                                    </div>
                                                                    <div className="dash-23 dane-price">

                                                                    </div>
                                                                </div>

                                                            ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={activeTab == 4 ? "content__tabs-content content__tabs-content--active" : "content__tabs-content"} data-tabs-target="from-airdrop">
                                            <div className=" ">
                                                <div className="">
                                                    <div className="js-profile-all">

                                                        <div className="table">
                                                            <div>
                                                                <div className="dash-tittle">
                                                                    <div scope="col" className="dash-23">Rank</div>
                                                                    <div scope="col" className="dash-54">User</div>
                                                                    <div scope="col" className="dash-23">Bid</div>
                                                                </div>
                                                            </div>


                                                            {mintBoard.length !== 0 ? mintBoard.map((item, index) => {
                                                                if(index < 500) {
                                                                    return (
                                                                        <div className={user === Object.keys(item)[0] ? 'dash-desc active epic' : 'dash-desc '} key={Object.keys(item)[0]}>
                                                                            <div className="dash-23 dane-id">{index + 1}</div>

                                                                            <div className="dash-54 gane-adapt">
                                                                                <input readOnly="readonly" id="copyurl9999"
                                                                                       data-type="id" data-length="0" data-wallet="meta"
                                                                                       className="refs form-control" type="text"
                                                                                       value={Object.keys(item)[0]}
                                                                                       name="id" />
                                                                                <button id="copy9999"
                                                                                        data-toggle="tooltip"
                                                                                        data-placement="bottom" title=""
                                                                                        className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                                        type="button"
                                                                                        data-original-title="Скопировать ссылку"
                                                                                        onClick={copyToClipboard(Object.keys(item)[0])}
                                                                                >
                                                                                    <img src={copy} alt="" />
                                                                                </button>
                                                                            </div>
                                                                            <div className="dash-23 dane-price">
                                                                                {item[Object.keys(item)[0]]} ETH
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                            }) : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
                                                                <div className='dash-desc' key={item}>
                                                                    <div className="dash-23 dane-id">{index + 1}</div>

                                                                    <div className="dash-54 gane-adapt">

                                                                    </div>
                                                                    <div className="dash-23 dane-price">

                                                                    </div>
                                                                </div>

                                                            ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            ) : (
                                <div className="content__tabs-wrapper flex-ir" style={{
                                    filter:'blur(10px)'
                                }}>
                                    <div className="content__tabs-inner ir-mw">
                                        <div className="content__tabs-content content__tabs-content--active"
                                             data-tabs-target="all">
                                            <div className=" ">
                                                <div className="">
                                                    <div className="js-profile-all">

                                                        <div className="table">
                                                            <div>
                                                                <div className="dash-tittle">
                                                                    <div scope="col" className="dash-23">Rank</div>
                                                                    <div scope="col" className="dash-54">User</div>
                                                                    <div scope="col" className="dash-23">Bid</div>
                                                                </div>
                                                            </div>
                                                            <div className="dash-desc">
                                                                <div className="dash-23 dane-id">3540</div>

                                                                <div className="dash-54 gane-adapt">
                                                                    <input readOnly="readonly" id="copyurl9999"
                                                                           data-type="id" data-length="0" data-wallet="meta"
                                                                           className="refs form-control" type="text"
                                                                           value="0x37e400ae8f1379b53b96d9fe726e72374d2223f2"
                                                                           name="id"/>
                                                                    <button id="copy9999"
                                                                            // onClick="copyLinkAttempt(event, 'copyurl9999', 'copy9999');return false"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom" title=""
                                                                            className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                            type="button"
                                                                            data-original-title="Скопировать ссылку">
                                                                        <img src="../topimgs/copybut.png" alt="" />
                                                                    </button>
                                                                </div>
                                                                <div className="dash-23 dane-price">0.0531 ETH ($182.44)
                                                                </div>
                                                            </div>
                                                            <div className="dash-desc">
                                                                <div className="dash-23 dane-id">3540</div>

                                                                <div className="dash-54 gane-adapt">
                                                                    <input readOnly="readonly" id="copyurl9999"
                                                                           data-type="id" data-length="0" data-wallet="meta"
                                                                           className="refs form-control" type="text"
                                                                           value="0x37e400ae8f1379b53b96d9fe726e72374d2223f2"
                                                                           name="id"/>
                                                                    <button id="copy9999"
                                                                            // onClick="copyLinkAttempt(event, 'copyurl9999', 'copy9999');return false"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom" title=""
                                                                            className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                            type="button"
                                                                            data-original-title="Скопировать ссылку">
                                                                        <img src="../topimgs/copybut.png" alt="" />
                                                                    </button>
                                                                </div>
                                                                <div className="dash-23 dane-price">0.0531 ETH ($182.44)
                                                                </div>
                                                            </div>
                                                            <div className="dash-desc">
                                                                <div className="dash-23 dane-id">3540</div>

                                                                <div className="dash-54 gane-adapt">
                                                                    <input readOnly="readonly" id="copyurl9999"
                                                                           data-type="id" data-length="0" data-wallet="meta"
                                                                           className="refs form-control" type="text"
                                                                           value="0x37e400ae8f1379b53b96d9fe726e72374d2223f2"
                                                                           name="id"/>
                                                                    <button id="copy9999"
                                                                            // onClick="copyLinkAttempt(event, 'copyurl9999', 'copy9999');return false"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom" title=""
                                                                            className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                            type="button"
                                                                            data-original-title="Скопировать ссылку">
                                                                        <img src="../topimgs/copybut.png" alt="" />
                                                                    </button>
                                                                </div>
                                                                <div className="dash-23 dane-price">0.0531 ETH ($182.44)
                                                                </div>
                                                            </div>
                                                            <div className="dash-desc">
                                                                <div className="dash-23 dane-id">3540</div>

                                                                <div className="dash-54 gane-adapt">
                                                                    <input readOnly="readonly" id="copyurl9999"
                                                                           data-type="id" data-length="0" data-wallet="meta"
                                                                           className="refs form-control" type="text"
                                                                           value="0x37e400ae8f1379b53b96d9fe726e72374d2223f2"
                                                                           name="id"/>
                                                                    <button id="copy9999"
                                                                            // onClick="copyLinkAttempt(event, 'copyurl9999', 'copy9999');return false"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom" title=""
                                                                            className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                            type="button"
                                                                            data-original-title="Скопировать ссылку">
                                                                        <img src="../topimgs/copybut.png" alt=""/>
                                                                    </button>
                                                                </div>
                                                                <div className="dash-23 dane-price">0.0531 ETH ($182.44)
                                                                </div>
                                                            </div>
                                                            <div className="dash-desc">
                                                                <div className="dash-23 dane-id">3540</div>

                                                                <div className="dash-54 gane-adapt">
                                                                    <input readOnly="readonly" id="copyurl9999"
                                                                           data-type="id" data-length="0" data-wallet="meta"
                                                                           className="refs form-control" type="text"
                                                                           value="0x37e400ae8f1379b53b96d9fe726e72374d2223f2"
                                                                           name="id"/>
                                                                    <button id="copy9999"
                                                                            // onClick="copyLinkAttempt(event, 'copyurl9999', 'copy9999');return false"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom" title=""
                                                                            className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                            type="button"
                                                                            data-original-title="Скопировать ссылку">
                                                                        <img src="../topimgs/copybut.png" alt=""/>
                                                                    </button>
                                                                </div>
                                                                <div className="dash-23 dane-price">0.0531 ETH ($182.44)
                                                                </div>
                                                            </div>
                                                            <div className="dash-desc">
                                                                <div className="dash-23 dane-id">3540</div>

                                                                <div className="dash-54 gane-adapt">
                                                                    <input readOnly="readonly" id="copyurl9999"
                                                                           data-type="id" data-length="0" data-wallet="meta"
                                                                           className="refs form-control" type="text"
                                                                           value="0x37e400ae8f1379b53b96d9fe726e72374d2223f2"
                                                                           name="id"/>
                                                                    <button id="copy9999"
                                                                            // onClick="copyLinkAttempt(event, 'copyurl9999', 'copy9999');return false"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom" title=""
                                                                            className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                            type="button"
                                                                            data-original-title="Скопировать ссылку">
                                                                        <img src="../topimgs/copybut.png" alt=""/>
                                                                    </button>
                                                                </div>
                                                                <div className="dash-23 dane-price">0.0531 ETH ($182.44)
                                                                </div>
                                                            </div>
                                                            <div className="dash-desc">
                                                                <div className="dash-23 dane-id">3540</div>

                                                                <div className="dash-54 gane-adapt">
                                                                    <input readOnly="readonly" id="copyurl9999"
                                                                           data-type="id" data-length="0" data-wallet="meta"
                                                                           className="refs form-control" type="text"
                                                                           value="0x37e400ae8f1379b53b96d9fe726e72374d2223f2"
                                                                           name="id"/>
                                                                    <button id="copy9999"
                                                                            // onClick="copyLinkAttempt(event, 'copyurl9999', 'copy9999');return false"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom" title=""
                                                                            className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                            type="button"
                                                                            data-original-title="Скопировать ссылку">
                                                                        <img src="../topimgs/copybut.png" alt=""/>
                                                                    </button>
                                                                </div>
                                                                <div className="dash-23 dane-price">0.0531 ETH ($182.44)
                                                                </div>
                                                            </div>
                                                            <div className="dash-desc">
                                                                <div className="dash-23 dane-id">3540</div>

                                                                <div className="dash-54 gane-adapt">
                                                                    <input readOnly="readonly" id="copyurl9999"
                                                                           data-type="id" data-length="0" data-wallet="meta"
                                                                           className="refs form-control" type="text"
                                                                           value="0x37e400ae8f1379b53b96d9fe726e72374d2223f2"
                                                                           name="id"/>
                                                                    <button id="copy9999"
                                                                            // onClick="copyLinkAttempt(event, 'copyurl9999', 'copy9999');return false"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom" title=""
                                                                            className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                            type="button"
                                                                            data-original-title="Скопировать ссылку">
                                                                        <img src="../topimgs/copybut.png" alt=""/>
                                                                    </button>
                                                                </div>
                                                                <div className="dash-23 dane-price">0.0531 ETH ($182.44)
                                                                </div>
                                                            </div>
                                                            <div className="dash-desc">
                                                                <div className="dash-23 dane-id">3540</div>

                                                                <div className="dash-54 gane-adapt">
                                                                    <input readOnly="readonly" id="copyurl9999"
                                                                           data-type="id" data-length="0" data-wallet="meta"
                                                                           className="refs form-control" type="text"
                                                                           value="0x37e400ae8f1379b53b96d9fe726e72374d2223f2"
                                                                           name="id"/>
                                                                    <button id="copy9999"
                                                                            // onClick="copyLinkAttempt(event, 'copyurl9999', 'copy9999');return false"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom" title=""
                                                                            className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                            type="button"
                                                                            data-original-title="Скопировать ссылку">
                                                                        <img src="../topimgs/copybut.png" alt=""/>
                                                                    </button>
                                                                </div>
                                                                <div className="dash-23 dane-price">0.0531 ETH ($182.44)
                                                                </div>
                                                            </div>
                                                            <div className="dash-desc">
                                                                <div className="dash-23 dane-id">3540</div>

                                                                <div className="dash-54 gane-adapt">
                                                                    <input readOnly="readonly" id="copyurl9999"
                                                                           data-type="id" data-length="0" data-wallet="meta"
                                                                           className="refs form-control" type="text"
                                                                           value="0x37e400ae8f1379b53b96d9fe726e72374d2223f2"
                                                                           name="id"/>
                                                                    <button id="copy9999"
                                                                            // onClick="copyLinkAttempt(event, 'copyurl9999', 'copy9999');return false"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom" title=""
                                                                            className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                            type="button"
                                                                            data-original-title="Скопировать ссылку">
                                                                        <img src="../topimgs/copybut.png" alt=""/>
                                                                    </button>
                                                                </div>
                                                                <div className="dash-23 dane-price">0.0531 ETH ($182.44)
                                                                </div>
                                                            </div>
                                                            <div className="dash-desc">
                                                                <div className="dash-23 dane-id">3540</div>

                                                                <div className="dash-54 gane-adapt">
                                                                    <input readOnly="readonly" id="copyurl9999"
                                                                           data-type="id" data-length="0" data-wallet="meta"
                                                                           className="refs form-control" type="text"
                                                                           value="0x37e400ae8f1379b53b96d9fe726e72374d2223f2"
                                                                           name="id"/>
                                                                    <button id="copy9999"
                                                                            // onClick="copyLinkAttempt(event, 'copyurl9999', 'copy9999');return false"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom" title=""
                                                                            className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                            type="button"
                                                                            data-original-title="Скопировать ссылку">
                                                                        <img src="../topimgs/copybut.png" alt=""/>
                                                                    </button>
                                                                </div>
                                                                <div className="dash-23 dane-price">0.0531 ETH ($182.44)
                                                                </div>
                                                            </div>
                                                            <div className="dash-desc">
                                                                <div className="dash-23 dane-id">3540</div>

                                                                <div className="dash-54 gane-adapt">
                                                                    <input readOnly="readonly" id="copyurl9999"
                                                                           data-type="id" data-length="0" data-wallet="meta"
                                                                           className="refs form-control" type="text"
                                                                           value="0x37e400ae8f1379b53b96d9fe726e72374d2223f2"
                                                                           name="id"/>
                                                                    <button id="copy9999"
                                                                            // onClick="copyLinkAttempt(event, 'copyurl9999', 'copy9999');return false"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom" title=""
                                                                            className="btn btn-outline-secondary waves-effect tstchngemw"
                                                                            type="button"
                                                                            data-original-title="Скопировать ссылку">
                                                                        <img src="../topimgs/copybut.png" alt=""/>
                                                                    </button>
                                                                </div>
                                                                <div className="dash-23 dane-price">0.0531 ETH ($182.44)
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="content__tabs-content" data-tabs-target="onfarm">
                                            <div className="content__tabs-inner">
                                                <div className="content__tabs-cards">
                                                    <div className="content__cards">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="content__tabs-content" data-tabs-target="from-mint">
                                            <div className="content__tabs-inner">
                                                <div className="content__tabs-cards">
                                                    <div className="content__cards">


                                                        <div className="scroll__top scroll__top--show" id="scrollTop">
                                                            <svg width="36" height="36">
                                                                <use href="img/sprite/sprite.svg#arrow-up"></use>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="content__tabs-content" data-tabs-target="from-airdrop">
                                            <div className="content__tabs-inner">
                                                <div className="content__tabs-cards">
                                                    <div className="content__cards">


                                                        <div className="scroll__top" id="scrollTop">
                                                            <svg width="36" height="36">
                                                                <use href="img/sprite/sprite.svg#arrow-up"></use>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <section className="features blast extrabid">
                <div className="container__small">
                    <div className="features__inner">
                        {auctionStart ?  <div className="features__info">
                            {bid === 0 ? (
                            <div className="features__details">
                                <p className="text bid__text">
                                    You haven't placed a bid in ETH to participate
                                    in the NFT
                                    auction yet.
                                    Click the "Make a Bid" button and place your bid!
                                </p>
                            </div>
                        ) : (
                            <div className="features__details">
                                <p className="text bid__text">
                                    You can increase your bid in ETH to improve your position in the
                                    auction leaderboard
                                    or simply to strengthen your standing. Additional bids can be placed as long as the auction is ongoing.
                                </p>
                            </div>
                        )}

                        </div> :
                            <div className="features__info">

                                <div className="features__details">
                                    <p className="text bid__text">
                                        Your bid did not make it onto the leaderboard. You can withdraw your ETH.
                                    </p>
                                </div>
                            </div>
                        }


                        {auctionStart && <div className="features__image">
                            <div className="card__calculator-content" style={{justifyContent: 'center'}}>
                                <div className="txt__bid_changer">
                                    {auctionStart ? (
                                        <button className="card__calculator-btn card__btn " data-path="mint"  onClick={
                                            () => {
                                                if (user) {
                                                    setShowPlaceABidModal(true)
                                                } else {
                                                    setShowWalletModal(true)
                                                }
                                            } }>
                                            {bid === 0 ? 'Make a bid' : 'Add a bid'}
                                        </button>
                                    ) : (
                                        <button className="card__calculator-btn card__btn " data-path="mint"  onClick={getInvestBack}>
                                            Return a bid
                                        </button>
                                    )}


                                </div>
                                <div className="text my__bid">
                                    My total bids: {bid} ETH
                                </div>
                            </div>
                        </div>}

                    </div>
                </div>
            </section>
            <section className="twit-join twit__frame">
                <div className="container__small">
                    <div className="twit-join__box">
                        <div className="tbow__container">
                            <h2 className="twit-join__title">News on x (Twitter)</h2>
                            <a><button className="twit-main-button">Join DEGY</button></a>
                        </div>
                        <div className="twit-join__icon">
                            <img width="248" height="227" src={wtwight} alt="image" />
                        </div>
                    </div>
                </div>
            </section>
            {showModal && createPortal(
                <MainModal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    connectToMetamask={connectToMetamask}
                    connectToBinance={connectToBinance}
                    connectToWallet={connectToWallet}
                    connectToTrust={connectToTrust}
                />,
                document.body
            )}
            {showFarmModal && createPortal(
                <FarmModal isOpen={showFarmModal} onClose={() => setShowFarmModal(false)} />,
                document.body
            )}
            {showPlaceABidModal && createPortal(
                <PlaceABidModal
                    isOpen={showPlaceABidModal}
                    onClose={() => setShowPlaceABidModal(false)}
                    invest={investInDEGY}
                    balance={balance}
                    getUserBoard={getUserBoard}
                    getUserInfo={() => getUserInfo(user)}
                />,
                document.body
            )}
            {showWalletModal && createPortal(
                <MainModal
                    isOpen={showWalletModal}
                    onClose={() => setShowWalletModal(false)}
                    connectToMetamask={connectToMetamask}
                    connectToBinance={connectToBinance}
                    connectToWallet={connectToWallet}
                    connectToTrust={connectToTrust}
                />,
                document.body
            )}
        </main>

    )
}



