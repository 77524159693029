import Close from '../../assets/sprite/close.svg';
import Metamask from '../../assets/sprite/metamask.svg';
import Binance from '../../assets/sprite/binance.svg';
import Trust from '../../assets/sprite/trust.svg';
import WalletConnect from '../../assets/sprite/walletconnect.svg';
import {useAuthStore} from "../../store";
import {useEffect} from "react";
export const MainModal = ({isOpen, onClose, connectToMetamask, connectToBinance, connectToTrust, connectToWallet}) => {
    if (!isOpen) return null;

    return (
        <>
            <div className={isOpen ? "modal is-open" : 'modal'} onClick={onClose}></div>
            <div className={isOpen ? "modal__container modal-open" : 'modal__container'} data-animation="fade" data-speed="300" data-target="wallet">
                <div className="modal__wallet">
                    <div className="modal__head">
                        <div className="modal__title">Connect wallet</div>
                        <div className="modal__close modal-close"  onClick={onClose}>
                            <img src={Close} alt="" style={{
                                height: '16px',
                                width: '16px'
                            }}/>
                        </div>
                    </div>

                    <div className="modal__inner"><div className="modal__metamask" onClick={connectToMetamask} >
                        <img src={Metamask}
                             style={{
                                 height: '25px',
                                 width: '23px'
                             }}
                             alt=""
                        />
                        <div className="modal__metamask-name">Metamask</div>
                    </div>
                        <a href="#" className="modal__metamask"  onClick={connectToWallet}>
                            <img src={WalletConnect}
                                 style={{
                                     height: '22px',
                                     width: '35px'
                                 }}
                                 alt=""
                            />
                            <div className="modal__metamask-name">WalletConnect</div>
                        </a>
                        <a href="#" className="modal__metamask" onClick={connectToBinance}>
                            <img src={Binance}
                                 style={{
                                     height: '28px',
                                     width: '27px'
                                 }}
                                 alt=""
                            />
                            <div className="modal__metamask-name">Binance Chain</div>
                        </a>
                        <a href="#" className="modal__metamask"  onClick={connectToTrust}>
                            <img src={Trust}
                                 style={{
                                     height: '28px',
                                     width: '28px'
                                 }}
                                 alt=""
                            />
                            <div className="modal__metamask-name">Trust Wallet</div>
                        </a>
                    </div>

                    <div className="modal__bottom">
                        <div className="modal__text">Haven’t got a cryptowallet yet?</div>
                        <button className="wallet">Learn how to connect</button>
                    </div>
                </div>
            </div
            >

        </>
    )
}