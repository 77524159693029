import Logo from '../../assets/logo.png';
import { NavLink } from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {MainModal} from "../Modal";
import {useAuthStore} from "../../store";
import {DisconnectModal} from "../Modal/DisconnectModal";
export const Header = () => {
    const buttonRef = useRef(null);
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
    const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
    const user = useAuthStore(state => state.user);
    const {connectedTo,connectToMetamask, connectToBinance, connectToWallet, connectToTrust} = useAuthStore(state => state);
    const [isOpenBurger, setIsOpenBurger] = useState(false);

    const getReferralParam = () => {
        const ref = new URLSearchParams(window.location.search).get('ref');
        return ref ? `?ref=${ref}` : '';
    };

    const openBurger = (isOpen) => () => {
        setIsOpenBurger(!isOpen)
    }
    const sliceUserHash = (str)=> {
        return str.slice(0, 2) + '...' + str.slice(-4);
    }
    const openWalletModal = () => setIsWalletModalOpen(true);
    const closeWalletModal = () => setIsWalletModalOpen(false);

    useEffect(() => {
        closeWalletModal()
    }, [connectedTo]);
    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="header__inner">
                        <a className="header__logo" href={'/'}>
                            <img className='logo' width='100' height='100' src={Logo} alt='DEGY logo' />
                        </a>
                        <nav className="header__nav">
                            <ul className="header__nav-list nav">
                                <li className="nav__item">
                                    <NavLink to="/"
                                             className={({ isActive  }) =>
                                                 isActive ? 'nav-link nav__link--active' : 'nav-link'
                                             }
                                    >
                                        Main
                                    </NavLink>
                                </li>
                                <li className="nav__item">
                                    <NavLink to={`/mint${getReferralParam()}`}
                                             className={({ isActive  }) =>
                                                 isActive ? 'nav-link nav__link--active' : 'nav-link'
                                             }
                                    >
                                        Mint
                                    </NavLink>
                                </li>
                                <li className="nav__item">
                                    <NavLink to="/game"
                                             className={({ isActive  }) =>
                                                 isActive ? 'nav-link nav__link--active' : 'nav-link'
                                             }
                                    >
                                        Game
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>

                        <div className={isOpenBurger ? 'mobile-menu active' : 'mobile-menu'}>
                            <ul className="header__nav-menu">
                                <NavLink to='/'
                                         onClick={openBurger(isOpenBurger)}
                                         className={({ isActive  }) =>
                                             isActive ? 'header__nav-item active' : 'header__nav-item'
                                         }
                                >
                                    <span className="nav__link">Main</span>
                                </NavLink>
                                <NavLink to='/mint'
                                         onClick={openBurger(isOpenBurger)}
                                         className={({ isActive  }) =>
                                             isActive ? 'header__nav-item active' : 'header__nav-item'
                                         }
                                >
                                    <span className="nav__link">Mint</span>
                                </NavLink>
                                <NavLink to='/game'
                                         onClick={openBurger(isOpenBurger)}
                                         className={({ isActive  }) =>
                                             isActive ? 'header__nav-item active' : 'header__nav-item'
                                         }
                                >
                                    <span className="nav__link">Game</span>
                                </NavLink>
                            </ul>
                            <div className="profile">
                                <NavLink
                                    to={user ? "/profile" : "/profile-soon" }
                                    onClick={openBurger(isOpenBurger)}
                                >
                                    Profile
                                </NavLink>
                            </div>
                        </div>

                        <div className="header__btns">
                            <NavLink to={user ? "/profile" : "/profile-soon" }
                                     className="profile"
                            >
                                Profile
                            </NavLink>
                            {!user && <div className="wallet" data-path="wallet"  onClick={openWalletModal}>Connect wallet</div>}

                            {user && <div className="wallet wallet--connected" data-path="wallet" ref={buttonRef} onClick={() => setIsDisconnectModalOpen(!isDisconnectModalOpen)}>{sliceUserHash(user)}</div>}
                            {connectedTo && <DisconnectModal
                                isOpen={isDisconnectModalOpen}
                                buttonRef={buttonRef}
                                onClose={() => setIsDisconnectModalOpen(false)}
                            /> }


                        </div>
                        <button className={isOpenBurger ? 'burger-menu active' : 'burger-menu'}  onClick={openBurger(isOpenBurger)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                </div>
            </header>
            {!connectedTo && <MainModal
                isOpen={isWalletModalOpen}
                onClose={closeWalletModal}
                connectToMetamask={connectToMetamask}
                connectToBinance={connectToBinance}
                connectToWallet={connectToWallet}
                connectToTrust={connectToTrust}
            />}

        </>
    )
}