import {MainModal} from "../../components/Modal";
import NFT1 from "../../assets/nfts/nft1.png";
import NFT2 from "../../assets/nfts/nft2.png";
import NFT3 from "../../assets/nfts/nft3.png";
import NFT4 from "../../assets/nfts/nft4.png";
import NFT5 from "../../assets/nfts/nft5.png";
import NFT6 from "../../assets/nfts/nft6.png";
import NFT8 from "../../assets/nfts/nft8.png";
import NFT9 from "../../assets/nfts/nft9.png";
import NFT10 from "../../assets/nfts/nft10.png";
import NFT11 from "../../assets/nfts/nft11.png";
import NFT12 from "../../assets/nfts/nft12.png";
import NFT13 from "../../assets/nfts/nft13.png";
import NFT14 from "../../assets/nfts/nft14.png";
import NFT15 from "../../assets/nfts/nft15.png";
import NFT16 from "../../assets/nfts/nft16.png";
import WAY2 from "../../assets/way/way2.png";
import Details1 from "../../assets/details-img1.png";
import Details2 from "../../assets/details-img2.png";
import sprite from '../../assets/sprite/diamond.svg';
import ArrowDown from '../../assets/sprite/arrow-down.svg';
import BigBlast from '../../assets/bigblast.png';
import {SwiperSlide, Swiper} from "swiper/react";
import { Autoplay } from 'swiper/modules';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';
import {useEffect, useState, useRef } from "react";
import {Accordion} from "./components/Accordion";
import {RoadmapExample} from "../../components/Example/RoadmapExample";

export const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const swiperRef = useRef(null);

    // useEffect(() => {
    //     const swiperInstance = swiperRef.current.swiper;
    //     const wrapper = swiperInstance.el.querySelector('.swiper-wrapper');
    //     if (wrapper) {
    //         wrapper.classList.add('strelki-swiper');
    //         wrapper.classList.add('dimontalk');
    //     }
    // }, []);

    return (
        <>
            <main>
                <section className="discover">
                    <div className="container">
                        <div className="discover__inner">
                            <div className="discover__wrapper">
                                <div className="discover__block">
                                    <h1 className="title">Ready to go on a mission with <span>DEGY NFT?</span></h1>

                                    <div className="discover__info">
                                        <div className="discover__timer timer">
                                            <div className="timer__text" style={{fontSize: '150%'}}>COMING SOON</div>
                                        </div>

                                        <div className="discover__details">What will your character look like? Generate your unique NFT and see!</div>
                                    </div>
                                </div>

                                <div className="discover__block">
                                    <div className="discover__cards">
                                        <div className="discover__card card">
                                            <div className="card__image">
                                                <img className='card__img' width='211' height='211' src={NFT1} alt=''/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #1231</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status uncommon">
                                                        {/*<svg  width='10' height='10'>*/}
                                                        {/*    <use href={sprite}></use>*/}
                                                        {/*</svg>*/}
                                                        <img src={sprite} alt=""/>
                                                        <span>uncommon</span>
                                                    </div>
                                                    <div className="card__price">
                                                        {/*<svg  width='13' height='13'>*/}
                                                        {/*    <use href={sprite + '#bnb'}></use>*/}
                                                        {/*</svg>*/}
                                                        <img src={sprite} alt=""/>
                                                        {/*<span>0,989</span>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="discover__card card">
                                            <div className="card__image">
                                                <img className='card__img' width='100' height='100' src={NFT2} alt=''/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #12001</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status rare">
                                                        {/*<svg  width='10' height='10'>*/}
                                                        {/*    <use href={sprite + '#diamond'}></use>*/}
                                                        {/*</svg>*/}
                                                        <img src={sprite} alt=""/>
                                                        <span>rare</span>
                                                    </div>
                                                    <div className="card__price">
                                                        {/*<svg  width='13' height='13'>*/}
                                                        {/*    <use href={sprite + '#bnb'}></use>*/}
                                                        {/*</svg>*/}
                                                        <img src={sprite} alt=""/>
                                                        {/*<span>3,989</span>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="discover__card card">
                                            <div className="card__image">
                                                <img className='card__img' width='100' height='100' src={NFT3} alt=''/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #1309
                                                </div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status epic">
                                                        {/*<svg  width='10' height='10'>*/}
                                                        {/*    <use href={sprite + '#diamond'}></use>*/}
                                                        {/*</svg>*/}
                                                        <img src={sprite} alt=""/>
                                                        <span>epic</span>
                                                    </div>
                                                    <div className="card__price">
                                                        {/*<svg  width='13' height='13'>*/}
                                                        {/*    <use href={sprite + '#bnb'}></use>*/}
                                                        {/*</svg>*/}
                                                        <img src={sprite} alt=""/>
                                                        {/*<span>12,911</span>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="discover__scroll">scroll to see more</p>
                            <button className="arrow__down">
                                <img className="arrow__down-image" src={ArrowDown} alt="arrow down"/>
                            </button>
                        </div>
                    </div>
                </section>
                <section className="features blast">
                    <div className="container__small">
                        <div className="features__inner">
                            <div className="features__info">
                                <h2 className="subtitle">BUILT ON <span>BLAST</span></h2>

                                <div className="descr">...is the ability to farm DEGY tokens for a period of 60 days</div>

                                <div className="features__details">

                                    <p className="text">The rarity of NFT directly depends on the ability to farm and the size of the reward, for example, an NFT with a “Legendary” rarity will bring you 15,000 tokens per day.</p>


                                </div>
                            </div>

                            <div className="features__image">
                                <div className="card1">
                                    <div className="card__image">
                                            <img className="card__img" width="211" height="211" src={BigBlast} alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about" id="about-yak">
                    <div className="container__small">
                        <div className="about__inner">
                            <h2 className="subtitle">Welcome to <span>DEGY metaverse!</span>?</h2>

                            <div className="about__info">
                                <div className="about__info-image">
                                    <div className="about__image">
                                        <div className="about__img"></div>
                                    </div>
                                    <div className="about__image">
                                        <div className="about__img"></div>
                                    </div>
                                    <div className="about__image">
                                        <div className="about__img"></div>
                                    </div>
                                    <div className="about__image">
                                        <div className="about__img"></div>
                                    </div>
                                </div>

                                <div className="about__descr">
                                    <div className="descr">...THEY WILL NEVER BE REPLACED BY ROBOTS</div>
                                    <p className="text">DEGY is derived from Delivery Guys, cheerful <br /> and upbeat fellows with backpacks. In our world, delivery guys are true superheroes who help people get what they need anywhere in the world. And in times of lockdown, they've actually saved lives at times.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="nfts">
                    <div className="nfts__title">
                        <div className="container__small">
                            <h2 className="nfts__subtitle subtitle"><span></span> DEGY NFTs</h2>
                        </div>
                    </div>
                    <div className="nfts__sliders">
                        <Swiper
                            centeredSlides="true"
                            spaceBetween={30}
                            slidesPerView={"auto"}
                            loopedSlides={8000}
                            slidesPerGroup={1}
                            loopPreventsSlide={false}
                            loop={true}
                            speed={10000}
                            autoplay={{
                                delay: 0,
                                disableOnInteraction: false,
                                reverseDirection: true
                            }}
                            className="nfts__top-slider swiper-container"
                            modules={[Autoplay]}
                        >
                            <div className="nfts__top-slider swiper-container">
                                <div className="swiper-wrapper">
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT9} alt="" />
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #749</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status epic">
                                                        <img src={sprite} alt=""/>
                                                        <span>epic</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT4} alt="" />
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #403</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status uncommon">
                                                        <img src={sprite} alt=""/>
                                                        <span>uncommon</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT5} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #84</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status uncommon">
                                                        <img src={sprite} alt=""/>
                                                        <span>uncommon</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT1} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #4278</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status uncommon">
                                                        <img src={sprite} alt=""/>
                                                        <span>uncommon</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT6} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #8</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status common">
                                                        <img src={sprite} alt=""/>
                                                        <span>common</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT8} alt="" />
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #1554</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status rare">
                                                        <img src={sprite} alt=""/>
                                                        <span>rare</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT9} alt="" />
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #749</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status epic">
                                                        <img src={sprite} alt=""/>
                                                        <span>epic</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT1} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #4278</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status uncommon">
                                                        <img src={sprite} alt=""/>
                                                        <span>uncommon</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT1} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #4278</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status uncommon">
                                                        <img src={sprite} alt=""/>
                                                        <span>uncommon</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT6} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #8</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status common">
                                                        <img src={sprite} alt=""/>
                                                        <span>common</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </div>
                            </div>
                        </Swiper>

                        <Swiper
                            centeredSlides="true"
                            spaceBetween={30}
                            slidesPerView={"auto"}
                            loopedSlides={8000}
                            slidesPerGroup={1}
                            loopPreventsSlide={false}
                            loop={true}
                            speed={9000}
                            autoplay={{
                                delay: 0,
                                disableOnInteraction: false,
                                reverseDirection: false
                            }}
                            className="nfts__bot-slider swiper-container"
                            modules={[Autoplay]}
                        >
                            <div className="nfts__bot-slider swiper-container">
                                <div className="swiper-wrapper">
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT12} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #4621</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status common">
                                                        <img src={sprite} alt=""/>
                                                        <span>common</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT10} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #554</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status uncommon">
                                                        <img src={sprite} alt=""/>
                                                        <span>uncommon</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT11} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #2865</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status rare">
                                                        <img src={sprite} alt=""/>
                                                        <span>rare</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT15} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #3832</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status rare">
                                                        <img src={sprite} alt=""/>
                                                        <span>rare</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT12} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #4621</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status common">
                                                        <img src={sprite} alt=""/>
                                                        <span>common</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT13} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #94</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status epic">
                                                        <img src={sprite} alt=""/>
                                                        <span>epic</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT10} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #554</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status uncommon">
                                                        <img src={sprite} alt=""/>
                                                        <span>uncommon</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT14} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #4321</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status uncommon">
                                                        <img src={sprite} alt=""/>
                                                        <span>uncommon</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="nfts__slide swiper-slide">
                                        <div className="card">
                                            <div className="card__image">
                                                <img className="card__img" width="211" height="211" src={NFT15} alt=""/>
                                            </div>
                                            <div className="card__info">
                                                <div className="card__series">Delivery guys</div>
                                                <div className="card__title">Delivery Guy #3832</div>
                                                <div className="card__divider"></div>
                                                <div className="card__footer">
                                                    <div className="card__status rare">
                                                        <img src={sprite} alt=""/>
                                                        <span>rare</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </div>
                            </div>
                        </Swiper>
                    </div>
                </section>
                <section className="features">
                    <div className="container__small">
                        <div className="features__inner">
                            <div className="features__info">
                                <h2 className="subtitle">Generate your NFT to see <span>how it looks like</span></h2>

                                <div className="features__details">

                                    <p className="text">NFT is minted by a smart contract right after you claim it. Nobody knows what your character will look like, and what rarity type it will be. Try your luck and see what you got! Create your delivery squad to go on missions and become one of the top players in the game.</p>
                                    <div className="features__down">
                                        <img className="arrow__down-image" src={ArrowDown} alt="arrow down"  />
                                    </div>
                                    <ScrollAnimation
                                        animateIn="bounceInDown"
                                        animateOnce={true}
                                    >
                                        <div className="features__nft-status wow bounceInDown animated">

                                            <div className="card__status epic">
                                                <img src={sprite} alt=""/>
                                                <span>epic</span>
                                            </div>
                                            <div className="card__status rare">
                                                <img src={sprite} alt=""/>
                                                <span>rare</span>
                                            </div>
                                            <div className="card__status uncommon">
                                                <img src={sprite} alt=""/>
                                                <span>uncommon</span>
                                            </div>
                                            <div className="card__status common">
                                                <img src={sprite} alt=""/>
                                                <span>common</span>
                                            </div>
                                        </div>

                                    </ScrollAnimation>
                                </div>
                            </div>

                            <div className="features__image">
                                <div className="card">
                                    <div className="card__image">
                                            <img className="card__img" width="211" height="211" src={NFT2} alt="" />
                                    </div>
                                    <div className="card__info">
                                        <div className="card__series">Delivery guys</div>
                                        <div className="card__title">Delivery Guy #911</div>
                                        <div className="card__divider"></div>
                                        <div className="card__footer">
                                            <div className="card__status epic">
                                                <img src={sprite} alt=""/>
                                                <span>epic</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="details">
                    <div className="container__small">
                        <div className="details__inner">
                            <div className="details__block">
                                <div className="details__image">
                                    <img className="details__img" width="311" height="311" src={Details1} alt="" />
                                </div>

                                <div className="details__info">
                                    <div className="descr">they are cool</div>
                                    <p className="text">DEGY is derived from Delivery Guys, cheerful and upbeat fellows with backpacks. In our world, delivery guys are true superheroes who help people get what they need anywhere in the world. And in times of lockdown, they've actually saved lives at times. </p>
                                </div>
                            </div>

                            <div className="details__block">
                                <div className="details__image">
                                    <img className="details__img" width="311" height="311" src={Details2} alt="" />
                                </div>

                                <div className="details__info">
                                    <div className="descr">they are irreplaceable</div>
                                    <p className="text">To give these wonderful guys all the honor and glory, we've created an NFT collection with deliveryman characters in a stylish old-school pixel design. Each NFT is not only a unique art object, but also a ticket to the DEGY metaverse, where an online multiplayer game will be launched soon.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrap dimontalk">
                    <h2 className="subtitle">ROAD<span>MAP</span></h2>
                    <div className="items-wrap">
                        <div className="items marquee reverce">
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">Participation in the <span>BLAST</span> "Big Bang" Contest</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now"><span>Auction-sale</span> of 5555 NFTs on BLAST blockchain</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">Participation in the <span>BLAST</span> "Big Bang" Contest</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now"><span>Auction-sale</span> of 5555 NFTs on BLAST blockchain</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">Public sale of NFTs on the <span>BLAST blockchain</span></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">Beta version of the <span>game release</span></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">DEGY <span>AirDrop</span></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">Participation in the <span>BLAST</span> "Big Bang" Contest</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now"><span>Auction-sale</span> of 5555 NFTs on BLAST blockchain</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">Participation in the <span>BLAST</span> "Big Bang" Contest</div>
                                </div>
                            </div>
                        </div>
                        <div aria-hidden="true" className="items marquee reverce">
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">Participation in the <span>BLAST</span> "Big Bang" Contest</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now"><span>Auction-sale</span> of 5555 NFTs on BLAST blockchain</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">Participation in the <span>BLAST</span> "Big Bang" Contest</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now"><span>Auction-sale</span> of 5555 NFTs on BLAST blockchain</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">Public sale of NFTs on the <span>BLAST blockchain</span></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">Beta version of the <span>game release</span></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">DEGY <span>AirDrop</span></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">Participation in the <span>BLAST</span> "Big Bang" Contest</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now"><span>Auction-sale</span> of 5555 NFTs on BLAST blockchain</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="roadmap__way-item roadmap__way-item--now">
                                    <div className="roadmap__way-image">
                                        <img className="roadmap__way-img" width="52" height="52" src={WAY2} alt="" />
                                    </div>

                                    <div className="roadmap__way-title roadmap__way-title--now">Participation in the <span>BLAST</span> "Big Bang" Contest</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="features">
                    <div className="container__small">
                        <div className="features__inner features__wrap">
                            <div className="features__info">
                                <h2 className="subtitle"><span>TEAM</span></h2>

                                <div className="features__details">
                                    <div className="descr">The DEGY multiverse is brought to you by a close-knit team of 5 enthusiasts with a background in delivery business.</div>
                                    <p className="text">Now they are focused on shaping and bringing to life digital products, and DEGY is their flagship initiative.</p>

                                </div>
                            </div>

                            <div className="features__person">
                                <div className="white-back">
                                    <div className="features-profile__avatar">
                                        <img className="img-person profile__avatar-img" src={NFT14} height="75" width="75" alt="" />
                                    </div>
                                    <div className="person-title">Velocity Vince</div>
                                    <div className="person-desc">has once started his career as a delivery man in one of the top European logistics companies. Soon he was promoted to a local department manager and in 5 years took the business to a new level.</div>
                                    <div className="person-blue-desc">Now he is the DEGY SEO and mastermind.</div>
                                </div>
                            </div>
                            <div className="features__person">
                                <div className="white-back">
                                    <div className="features-profile__avatar">
                                        <img className="img-person profile__avatar-img" src={NFT3} height="75" width="75" alt=""  />
                                    </div>
                                    <div className="person-title">Frankie Flash</div>
                                    <div className="person-desc">used to do delivery as his summer job. Later he joined the company as an IT specialist and participated in creating a mobile app.</div>
                                    <div className="person-blue-desc">Now he is DEGY STO and head of the dev team.</div>
                                </div>
                            </div>
                            <div className="features__person">
                                <div className="white-back">
                                    <div className="features-profile__avatar">
                                        <img className="img-person profile__avatar-img" src={NFT16} height="75" width="75" alt=""  />
                                    </div>
                                    <div className="person-title">Bella Blitz</div>
                                    <div className="person-desc">is a delivery industry marketing professional who managed brand development for a worldwide delivery company.</div>
                                    <div className="person-blue-desc">Now she contributes her passion and skills to make DEGY stand out.</div>
                                </div>
                            </div>
                            <div className="features__person">
                                <div className="white-back">
                                    <div className="features-profile__avatar">
                                        <img className="img-person profile__avatar-img" src={NFT13} height="75" width="75" alt=""  />
                                    </div>
                                    <div className="person-title">Rob Roadrunner</div>
                                    <div className="person-desc">started working in the delivery industry as his part time job during the pandemic to help the local community. Later he got back to his regular job in the gaming industry.</div>
                                    <div className="person-blue-desc">He combined his experience and became the DEGY game designer.</div>
                                </div>
                            </div>
                            <div className="features__person">
                                <div className="white-back">
                                    <div className="features-profile__avatar">
                                        <img className="img-person profile__avatar-img" src={NFT11} height="75" width="75" alt=""  />
                                    </div>
                                    <div className="person-title">Mighty Miguel</div>
                                    <div className="person-desc">worked as a delivery guy to cover his college expenses. Shortly he discovered that he was enjoying it so much that he decided to make a career in the delivery business and started his own company. His small business is still up and running in his hometown.</div>
                                    <div className="person-blue-desc">Mighty Miguel is the head of financial management at DEGY.</div>
                                </div>
                            </div>



                        </div>
                    </div>
                </section>
                <section className="facts" id="faq-yak">
                    <div className="container__small">
                        <div className="facts__inner">
                            <h2 className="subtitle">GET THE <span>FACTS</span></h2>

                            <div className="facts__tabs">
                                <ul className="facts__tabs-list">
                                    <li className="facts__tabs-item">
                                        <button className="facts__tabs-btn facts__tabs-btn--active" data-tabs-path="general">General</button>
                                    </li>
                                </ul>

                                <div className="facts__tabs-content facts__tabs-content--active" data-tabs-target="general">
                                    <div className="tabs__content-inner">
                                        <div className="facts__accordion accordion" data-spollers="" data-one-spoller="">
                                            <Accordion />
                                            {/*<div className="facts__accordion-item accordion__item">*/}
                                            {/*    <button tabIndex="-1" type="button" data-spoller="" className="facts__accordion-title _active"><span>What is DEGY.IO and what do you offer?</span></button>*/}
                                            {/*    <div className="facts__accordion-text">*/}
                                            {/*        DEGY is a platform combining a unique NFT collection and an indie blockchain game with a universe of Delivery Guys. Minted NFTs will be required to participate in the game and send Delivery Guys on missions. The more NFT you have, the more tasks you can accomplish in the Game.*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className="facts__accordion-item accordion__item">*/}
                                            {/*    <button tabIndex="-1" type="button" data-spoller="" className="facts__accordion-title">What is a DEGY NFT Collection?</button>*/}
                                            {/*    <div className="facts__accordion-text testhidetextnew">*/}
                                            {/*        DEGY Collection features <span>6,000</span> unique NFTs. <span>5,000</span> are allocated for the public sale. <span>1,000</span> are reserved for the Private sale. When you mint an NFT, it is automatically generated for you. Features of your NFT character are selected randomly, as well as its rareness. The odds of minting a common NFT are better. However, if you are lucky enough, you can obtain an Uncommon, Rare, or even an Epic one*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className="facts__accordion-item accordion__item">*/}
                                            {/*    <button tabIndex="-1" type="button" data-spoller="" className="facts__accordion-title">What is a DEGY GAME?</button>*/}
                                            {/*    <div className="facts__accordion-text">*/}
                                            {/*        DEGY Game is an indie multiplayer blockchain game for DEGY NFT holders. To get access to the game, a player must have at least one DEGY NFT. Each NFT is a game character. Players can send their Delivery Guys on missions and get rewarded with in-game tokens.*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className="facts__accordion-item accordion__item">*/}
                                            {/*    <button tabIndex="-1" type="button" data-spoller="" className="facts__accordion-title">How to purchase NFT?</button>*/}
                                            {/*    <div className="facts__accordion-text">*/}
                                            {/*        At the moment, the only way to purchase DEGY NFT is to participate in the official Private Sale and Public Sale. After that, NFT will be listed on NFT marketplaces and traded at market prices.*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className="facts__accordion-item accordion__item">*/}
                                            {/*    <button tabIndex="-1" type="button" data-spoller="" className="facts__accordion-title">What’s next?</button>*/}
                                            {/*    <div className="facts__accordion-text">*/}
                                            {/*        DEGY NFT Sale is the first step in an exciting journey we’re taking. The next step is the Game release. DEGY Roadmap also includes a list of future additions and scenarios to keep the players motivated. We plan to expand the game universe, as well as add new forms of player interaction and reward mechanics. And NFT will be the key to all future projects.*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                </section>
            </main>

            <MainModal/>
        </>
    )
}