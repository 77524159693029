import React from "react";
import {AppLayout} from "../../layouts/AppLayout";
import {Home} from "../../pages/Main/Home";
import {Game} from "../../pages/Game/Game";
import {Mint} from "../../pages/Mint/Mint";
import {Profile} from "../../pages/Profile/Profile";
import {ProfileSoon} from "../../pages/Profile/ProfileSoon";

export const RoutesConfig = [
    {
        path: "/",
        element: <AppLayout />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/mint",
                element: <Mint />,
            },
            {
                path: "/game",
                element: <Game/>,
            },
            {
                path: "/profile",
                element: <Profile/>,
            },
            {
                path: "/profile-soon",
                element: <ProfileSoon/>,
            },
            {
                path: "/.well-known/walletconnect.txt",
                element: <div></div>,
            },
        ]
    },
]