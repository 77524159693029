import {Header} from "../components/Header";
import {Outlet, useNavigate} from "react-router-dom";
import {Footer} from "../components/Footer";
import {useAuthStore} from "../store";
import {useEffect} from "react";

export const AppLayout = () => {

    const {
        checkConnection
    } = useAuthStore(state => state);

    useEffect(() => {
        checkConnection();
    }, [checkConnection]);
    return (
        <>
            <Header />
                <Outlet />
            <Footer />
        </>
    )
}